<template>
  <div class="search-results-container">
    <SearchResults class="pb-0" @touchmove="emitScrollEvent">
      <template #content>
        <SearchResultsItem
          v-if="showPopularQueries"
          :append-query="true"
          :is-inline="!$device.isMobile"
          :items="popularQueries"
          :title="$t('layout.searchResult.popularQueries')"
          :query-text="queryText"
          item-type="brand"
          class="popular-searches"
          @save-recent-search="saveRecentSearch"
          @toggle-active="toggleActive"
          @track-clicked-item="
            (searchResultSlug) =>
              trackClickedItem(
                'search_menu_popular_results_click_ssr',
                undefined,
                searchResultSlug
              )
          "
        />

        <SearchResultsItem
          v-if="showRecentSearches"
          :items="recentSearches"
          :show-clear-button="true"
          :title="$t('layout.searchResult.recentSearches')"
          @clear-search="clearSearch('recentSearches')"
          @save-recent-search="saveRecentSearch"
          @toggle-active="toggleActive"
        />

        <SearchResultsProduct
          v-if="showRecentProducts"
          :products="recentProducts"
          :show-clear-button="true"
          :title="$t('layout.searchResult.recentlyViewedProducts')"
          :query-text="queryText"
          @clear-search="clearSearch('recentProducts')"
          @save-recent-search="saveRecentSearch"
          @toggle-active="toggleActive"
        />

        <Loader :loading="loading" class="loader-container pb-0">
          <SearchResultsItem
            v-if="showSuggestedBrands"
            :append-query="true"
            :highlight-text="true"
            :items="suggestedBrands"
            :title="$t('layout.searchResult.suggestedBrands')"
            item-type="brand"
            :query-text="queryText"
            class="suggested-brands"
            @save-recent-search="saveRecentSearch"
            @toggle-active="toggleActive"
            @set-search-query-id-cookie="setSearchQueryIdCookie"
            @track-clicked-item="
              (searchResultSlug) =>
                trackClickedItem(
                  'search_menu_suggested_result_click_ssr',
                  'brand',
                  searchResultSlug
                )
            "
          />

          <SearchResultsItem
            v-if="showSuggestedCategories"
            :append-query="true"
            :highlight-text="true"
            :items="suggestedCategories"
            :title="$t('layout.searchResult.suggestedCategories')"
            :query-text="queryText"
            item-type="category"
            class="suggested-categories"
            @save-recent-search="saveRecentSearch"
            @toggle-active="toggleActive"
            @set-search-query-id-cookie="setSearchQueryIdCookie"
            @track-clicked-item="
              (searchResultSlug) =>
                trackClickedItem(
                  'search_menu_suggested_result_click_ssr',
                  'category',
                  searchResultSlug
                )
            "
          />

          <SearchResultsProduct
            v-if="showSuggestedProducts"
            :products="suggestedProducts"
            :title="$t('layout.searchResult.suggestedProducts')"
            :query-text="queryText"
            class="suggested-products"
            @save-recent-search="saveRecentSearch"
            @toggle-active="toggleActive"
            @set-search-query-id-cookie="setSearchQueryIdCookie"
            @track-clicked-item="
              (searchResultSlug) =>
                trackClickedItem(
                  'search_menu_suggested_result_click_ssr',
                  'product',
                  searchResultSlug
                )
            "
          />

          <template v-if="showNoResults">
            <p class="no-results-text">
              {{ $t('layout.searchResult.noResult') }}
            </p>
            <ul class="search-tips">
              <li>{{ $t('layout.searchResult.spellingTip') }}</li>
              <li>{{ $t('layout.searchResult.termsTip') }}</li>
            </ul>
          </template>
        </Loader>
      </template>

      <template v-if="showViewAllButton" #view-results-button>
        <div class="button-container">
          <SmartLink :to="slugWithQuery('/search')">
            <Button
              :label="$t('layout.searchResult.viewAllResultButton')"
              size="is-fullwidth"
              @click="searchButtonClicked"
            />
          </SmartLink>
        </div>
      </template>
    </SearchResults>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pluralize from 'pluralize'

import Button from '@sephora-asia/core-button/Button.vue'
import Loader from '@sephora-asia/core-loader/Loader.vue'
import SearchResults from '@sephora-asia/core-search-results/SearchResults.vue'

import searchMixin from '~/mixins/searchMixin.js'
import SearchResultsItem from '~/components/SearchResultsItem.vue'
import SearchResultsProduct from '~/components/SearchResultsProduct.vue'

export default {
  name: 'SearchResultsContainer',

  components: {
    Button,
    Loader,
    SearchResults,
    SearchResultsItem,
    SearchResultsProduct
  },

  mixins: [searchMixin],

  props: {
    isScrolling: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    popularQueries: {
      type: Array,
      default: () => []
    },
    suggestedBrands: {
      type: Array,
      default: () => []
    },
    suggestedCategories: {
      type: Array,
      default: () => []
    },
    suggestedProducts: {
      type: Array,
      default: () => []
    },
    queryText: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      recentSearches: 'search/recentSearches',
      recentProducts: 'search/recentProducts',
      searchQueryId: 'search/searchQueryId'
    }),
    showNoResults() {
      return (
        this.queryText.length > 1 &&
        !this.showSuggestedBrands &&
        !this.showSuggestedCategories &&
        !this.showSuggestedProducts
      )
    },
    showPopularQueries() {
      return (
        this.queryText.length > 1 ||
        (this.recentSearches.length === 0 && this.recentProducts.length === 0)
      )
    },
    showRecentSearches() {
      return this.queryText.length < 2 && this.recentSearches.length > 0
    },
    showRecentProducts() {
      return this.queryText.length < 2 && this.recentProducts.length > 0
    },
    showSuggestedBrands() {
      return this.suggestedBrands.length > 0
    },
    showSuggestedCategories() {
      return this.suggestedCategories.length > 0
    },
    showSuggestedProducts() {
      return this.suggestedProducts.length > 0
    },
    showViewAllButton() {
      return this.queryText.length > 1 && !this.showNoResults
    }
  },

  mounted() {
    this.$store.dispatch('search/fetchRecentSearches')
    this.$store.dispatch('search/fetchRecentProducts')
  },

  methods: {
    toggleActive(value) {
      this.$emit('toggle-active', value)
    },
    trackClickedItem(event, entityType, searchResultSlug) {
      const data = {
        params: {
          search_term: this.queryText,
          search_result_slug: searchResultSlug
        }
      }

      if (entityType) {
        data.params.search_type = entityType
      }

      this.$analytics.trackEvent(event, data)
    },
    emitScrollEvent() {
      // emitting an event to tell searchbar component that user is scrolling search result container
      // isScrolling is set to true when user start scrolling and this condition below is to prevent the event being emitted multiple time
      if (!this.isScrolling) {
        this.$emit('scrolling-search-result-container')
      }
    },
    searchButtonClicked() {
      this.saveRecentSearch({
        name: this.queryText,
        slug: this.slugWithQuery('/search')
      })
      this.$analytics.trackEvent('search_menu_view_all_results_click_ssr', {
        params: {
          search_term: this.queryText
        }
      })
      this.$emit('toggle-active', false)
    },
    setSearchQueryIdCookie({ entityType, path }) {
      this.$cookies.set(
        'search-query-id',
        this.searchQueryId[pluralize(entityType)],
        {
          maxAge: 2 * 60 * 60, // 2 hours
          path
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results-container {
  position: absolute;
  z-index: $zindex-modal;

  @include mobile {
    left: 0;
    right: 0;
  }

  @include tablet {
    left: 8%;
    right: 8%;
  }

  @include desktop {
    left: 15%;
    right: 15%;
  }

  @include widescreen {
    left: 18.5%;
    right: 18.5%;
  }

  .loader-container {
    padding-bottom: 20px;
  }

  .no-results-text {
    padding-top: 20px;
  }

  ul.search-tips {
    list-style-type: disc;
    padding-bottom: 40px;

    li {
      list-style-position: inside;
    }
  }

  .button-container {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 190px;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
      width: 100%;
    }
  }

  /deep/ .search-results-section:last-of-type {
    margin-bottom: 0;
  }
}
</style>
